import React from "react";
import { Row, Col } from "antd";
import Image from "../ImageQuerys/LocalImages";

const Partners = () => {
  return (
    <Row
      align="middle"
      gutter={20}
      style={{ marginTop: 70, marginBottom: 70, margin: "auto" }}
    >
      <Col xs={8} md={4}>
        <Image
          imageName="saklen.png"
          style={{
            maxWidth: 100,
            maxHeight: 100,
            zIndex: 2,
            margin: "auto",
          }}
          className="local-logos"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="lakeside.png"
          style={{ maxWidth: 100, maxHeight: 100, margin: "auto" }}
          className="local-logos"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="lake-eric.png"
          style={{ maxWidth: 100, maxHeight: 100, margin: "auto" }}
          className="local-logos"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="saint-george.png"
          style={{ maxWidth: 150, maxHeight: 150 }}
          className="local-logos-large"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="warren.png"
          style={{ maxWidth: 200, maxHeight: 200, zIndex: 2 }}
          className="local-logos-large"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="russell.png"
          style={{ maxWidth: 150, maxHeight: 150 }}
          className="local-logos-large"
        />
      </Col>
    </Row>
  );
};

export default Partners;
